
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import StationSelect from "@/components/order/StationSelect.vue";

export default defineComponent({
  components: {
    Grid,
    StationSelect
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const query = reactive({
      area: 0,
      station: 0,
      date: ""
    });

    const gridOptions: GridOptions = {
      stripe: false,
      title: "保險資料",
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        // custom: true,
        // refresh: true
      },
      exportConfig: {
        filename: "保險資料",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: [{ field: "Name" }, { field: "PersonalId" }, { field: "BirthDate" }, { field: "MobilePhone" }]
      },
      columns: [
        // {
        //   field: "Number",
        //   title: "取車編號",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   minWidth: 150,
        // },
        {
          field: "Name",
          title: "騎乘者姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 100,
        },
        {
          field: "PersonalId",
          title: "身分證字號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 150,
        },
        {
          field: "BirthDate",
          title: "生日",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 150,
          formatter({ cellValue }) {
            return CloudFun.utils.formatDate(cellValue, "yyyy-MM-dd")
          }
        },
        {
          field: "MobilePhone",
          title: "連絡電話",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: 150,
          formatter({ cellValue }) {
            return CloudFun.utils.formatPhone(cellValue) + '\t'
          }
        },
        {
          field: "OrderItem.Bike.Number",
          title: "車號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: 100
        },
        {
          field: "Order.StartTime",
          title: "取車時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          formatter({ cellValue }) {
            return CloudFun.utils.formatDate(cellValue, "yyyy-MM-dd HH:mm:ss")
          }
        },
        {
          field: "Order.EndTime",
          title: "還車時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "150",
          formatter({ cellValue }) {
            return CloudFun.utils.formatDate(cellValue, "yyyy-MM-dd HH:mm:ss")
          }
        },
        // {
        //   field: "type",
        //   title: "車輛型號",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   width: "100",
        // },
        // {
        //   field: "typeNumber",
        //   title: "車輛編號",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   width: "100",
        // },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Order.BookingTime", order: 1 });
          params.condition!.and("Order.Status", Operator.Equal, (model.enums.OrderStatus as any).Finished.Value);
          if (query.station) params.condition!.and("Order.StationId", Operator.Equal, query.station);
          else if (query.area) params.condition!.and("Station.AreaId", Operator.Equal, query.area);
          if (query.date) {
            const splits = query.date.split(' - ')
            params.condition!.and("Order.BookingTime", Operator.GreaterThanOrEqual, `${splits[0]} 00:00:00`);
            params.condition!.and("Order.BookingTime", Operator.LessThanOrEqual, `${splits[1]} 23:59:59`);
          }
          return model.dispatch('insuranceRecord/query', params);
        } : undefined,
        queryAll: model ? () => {
          const params = { sortings: [{ column: "Order.BookingTime", order: 1 }], condition: new Condition() }
          params.condition.and("Order.Status", Operator.Equal, (model.enums.OrderStatus as any).Finished.Value);
          if (query.station) params.condition.and("Order.StationId", Operator.Equal, query.station);
          if (query.date) {
            const splits = query.date.split(' - ')
            params.condition.and("Order.BookingTime", Operator.GreaterThanOrEqual, `${splits[0]} 00:00:00`);
            params.condition.and("Order.BookingTime", Operator.LessThanOrEqual, `${splits[1]} 23:59:59`);
          }
          return model.dispatch('insuranceRecord/query', params);
        } : undefined,
        save: model
          ? (params) => model.dispatch('insuranceRecord/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    };

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const endDate = new Date();
    const datepickerOptions = {
      autoApply: false,
      showWeekNumbers: true,
      singleMode: false,
      maxDate: endDate,
      dropdowns: {
        minYear: endDate.getFullYear() - 1,
        months: true,
        years: true,
      },
      buttonText: {
        apply: "選取",
        cancel: "取消"
      },
      format: "YYYY/MM/DD",
      lang: "zh-TW"
    };
    query.date = `${CloudFun.utils.formatDate(startDate, "yyyy/MM/dd")} - ${CloudFun.utils.formatDate(endDate, "yyyy/MM/dd")}`;
    return {
      query,
      grid,
      gridOptions,
      datepickerOptions
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  },
});
